<template>
    <div class="ol-body" style="margin-top:0px;background:#ffffff;">
        <div class="back-home">
            <a href="/"><i class="fa fa-arrow-left"></i> &nbsp; Kembali Belanja</a>
        </div>
        <header>
            <div class="header-content">
                <div class="logo-interior">
                    <a href="/desain-interior">
                        <img src="https://cdn.morefurniture.id/MORE/asset/morelogo_nd.png" alt="Logo More Furniture">
                    </a>
                </div>
                <nav class="menu-interior">
                    <ul>
                        <li><a href="/desain-interior#ruangan" class="scrollLink">Ruangan</a></li>
                        <li><a href="/desain-interior#proyek" class="scrollLink">Proyek</a></li>
                        <li><a href="/desain-interior#testimoni" class="scrollLink">Testimoni</a></li>
                        <!-- <li><a href="interior#faq" class="scrollLink">FAQ</a></li> -->
                    </ul>
                </nav>
                <div class="consultation-button">
                    <a href="http://bit.ly/more-custom" target="_blank">Konsultasi Sekarang</a>
                </div>
                <div class="burger-menu" @click="toggleMenu">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </header>
        <div class="mobile-menu" id="mobileMenu">
            <ul>
                <li><a href="/desain-interior#ruangan" class="scrollLink">Ruangan</a></li>
                <li><a href="/desain-interior#proyek" class="scrollLink">Proyek</a></li>
                <li><a href="/desain-interior#testimoni" class="scrollLink">Testimoni</a></li>
                <!-- <li><a href="interior#faq" class="scrollLink">FAQ</a></li> -->
            </ul>
        </div>
        <div style="margin-top:130px;"></div>
        
        <div class="ol-wrapper ol-wrapper--large margin-top" id="proyek">
            <div class="ol-product-content ol-product-content-detail add-fix ">
                <div class="ol-product-content-detail__images">
                    <div class="ol-product-content-detail__images-list">

                        <div class="ol-product-content-detail__images-list-single">
                            <!-- <figure  class="ol-product-content-detail__images-list-single-figure" :data-src="this.interior.interior.image">
                                <div class="ol-product-img-container">
                                    <img class="og-brand-bearland" :src="this.interior.interior.image" :alt="this.interior.interior.customer" :title="this.interior.interior.customer">
                                </div>
                            </figure> -->
                            <figure v-for="image in this.interior.image" :key="'images-list-single-'+image.id" class="ol-product-content-detail__images-list-single-figure" :data-src="image.image_url">
                                <div class="ol-product-img-container">
                                    <img class="og-brand-bearland" :src="image.image_url" style="height: auto;" >
                                </div>
                            </figure>
                        </div>
                        <div class="ol-product-content-detail__images-list-group">
                            
                            <!-- <figure :id="'detail-0'" class="ol-product-content-detail__images-list-group-figure item-detail-images-suasana">
                                <img :src="this.interior.interior.image" :alt="this.interior.interior.customer" :title="this.interior.interior.customer" style="margin:0 auto;">
                            </figure> -->
                            <figure v-for="image in this.interior.image" :key="'detail-'+image.id" :id="'detail-'+(image.id).toString()" class="ol-product-content-detail__images-list-group-figure item-detail-images">
                                <img :src="image.image_url" style="margin: auto;">
                            </figure>
                        </div>
                    </div>
                </div>
                <div class="ol-product-content-detail__desc detail-interior" style="margin-bottom:0px;">

                    <h1 style="line-height:normal;" class="ol-home-product__content-item-list--item-title name-product" > 
                        {{this.interior.interior.customer}}
                    </h1>
                    <div class="break-16"></div>
                    <div class="row no-gutters" style="background: #F6F7F9;padding: 16px;">
                        <div class="col-4">
                            <div>Lokasi : </div>
                            <b>{{this.interior.interior.location}}</b>
                        </div>
                        <div class="col-4">
                            <div>Desain :</div>
                            <b>{{this.interior.interior.design}}</b>
                        </div>
                        <div class="col-4">
                            <div>Ruangan :</div>
                            <b>{{this.interior.interior.category}}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="padding-usp-interior">
            <div class="ol-wrapper ol-wrapper--large" >
                <div class="title-div-content">
                    <h2 class="heading-content">Keunggulan More Custom</h2>
                    <span class="sub-heading-content">Nikmati Banyak Keunggulan Jasa Custom Interior MORE dengan Hasil yang Terbaik!</span>
                </div>
                <div class="row no-gutters" style="margin-top:32px;font-weight: 700;font-size: 12px;color: #141414;"> 
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/konsultasi.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Gratis <br>Interior</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/desain.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Desain Interior<br> Milenial</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/garansi.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Garansi <br>2 Tahun</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/transparan.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Harga<br> Transparan</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/cicilan.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Cicilan<br> 0%</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/tim.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Tim <br>Berpengalaman</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="ol-wrapper ol-wrapper--large margin-top" >
            <div class="row no-gutters" style="background: #F6F7F9;">
            <div class="col-md-12 col-lg-6">
                <div class="content-location">
                    <div class="content-location-detail">
                        <h4 style="font-weight: 800;line-height: 34px;color: #141414;">Lebih Dekat dengan MORE Custom Interior!</h4>
                        <div class="content-location-info">
                            <table>
                            <tr>
                                <td style="padding:4px;"><img :src="require('@/assets/images/interior/location.png')"  width="16" height="16" style="vertical-align: top;"/></td>
                                <td style="padding:4px;">Jl. Mayjen HR. Muhammad No.33, Pradahkalikendal, Kec. Dukuhpakis, Surabaya, Jawa Timur 60226</td>
                            </tr>
                            <tr>
                                <td style="padding:4px;"><img :src="require('@/assets/images/interior/time.png')"  width="16" height="16" /></td>
                                <td style="padding:4px;">09:00 - 21:00</td>
                            </tr>
                            <tr>
                                <td style="padding:4px;"><img :src="require('@/assets/images/interior/phone.png')"  width="16" height="16" /></td>
                                <td style="padding:4px;">+62 811 306 6171</td>
                            </tr>
                            </table>
                        </div>
                        <div style="margin-top:16px;margin-bottom:16px;">
                            <a class="content-location-button-1" href="https://www.instagram.com/moreinterior.id/" target="_blank">Ikuti Kami di Instagram</a>
                            <a class="content-location-button-2" href="https://maps.app.goo.gl/y8WwqUPFLWdjVzTg6" target="_blank">Menuju Lokasi</a>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <img :src="require('@/assets/images/interior/Facade-More-521x316.jpg')"  width="100%" height="100%" />
            </div>
            </div>
        </div>
        <div class="break-24 retail-dekstop"></div>
        <div class="break-24 retail-dekstop"></div>
        <div class="break-24 retail-dekstop"></div>
    </div>    
</template>

<script>
import globalUrl from '@/util/globallUrl'
import ListCustomer from "@/components/interior/ListCustomer.vue"

const URL = globalUrl.WS_KONSULTASI;
import { mapGetters,mapActions } from 'vuex'
export default {
    name: "InteriorDetail",
    components:{
        ListCustomer
    },
    data(){
        return {
            category:"",
            statusReset:false,
            filteredBy:this.$store.state.product.list.filtered,
            loading:true,
            list:{
                more:false,
                total:0,
            },
            DATA:null,
        }
    },
    computed:{
        ...mapGetters('auth', ['location','authenticated']),
        ...mapGetters("interior",["items","filtered","interior"]),
    },
    methods:{
        ...mapActions("interior",["fetchItems","fetchDetail"]),
        scrollToHash(hash) {
            hash = hash.replace('#', '');
                // const h = document.getElementsByTagName('header')[0].offsetHeight + document.getElementById('header-promotion').offsetHeight;
                const el = document.getElementById(hash);
                if(el) {
                    var top = el.getBoundingClientRect().top + window.pageYOffset;
                    top= top-110;
                    console.log(top);
                    window.scrollTo({top: top, behavior: 'smooth'});
                    this.$router.push({hash: hash});
            }
        },
        
        toggleMenu() {
            var menu = document.getElementById("mobileMenu");
            menu.classList.toggle("show");
        },
        resetProgress(itemId) {
            const progressInner = document.getElementById(`progress-inner${this.steps.findIndex(item => item.id === itemId) + 1}`);
            progressInner.style.width = '0';
            progressInner.style.transition = 'none';
        },
        animateProgress(index) {
            const progressInner = document.getElementById(`progress-inner${index + 1}`);
            progressInner.style.transition = 'width 5s linear';
            progressInner.style.width = '100%';
        },
        handleClick(index) {
            this.currentIndex = index;
            this.activateItem(index);
        },
        async getItems(firstPage = false,per_page=null){
            this.loading = true;
            
            let result = await this.fetchDetail(this.$route.params.slug);
            
            this.loading = false;
            
            this.DATA = result.data;
            
            if(!result.success){
                this.$router.push("/404");
                return false;
            }
        },
        retrieveCopiedFilter(){
            this.statusReset = true;
            let query = _.mapKeys(queryString.parse(this.$route.params.filter), (value, key) => key.replace("[]", "") );

            query.per_page = this.filteredBy.per_page;
            query.page = 1;
            
            this.filteredBy = query;
            
            this.$store.commit("interior/setCopiedFilter", false);
            
        },
        jqUnslick(){
            if($('.ol-product-content-detail__images-list-single').hasClass("slick-initialized")) $('.ol-product-content-detail__images-list-single').slick('unslick');
            if($('.ol-product-content-detail__images-list-group').hasClass("slick-initialized")) $('.ol-product-content-detail__images-list-group').slick('unslick');      
        },
        jqSlick(){
            
            // if(!this.loading){
                setTimeout(() => {
                    $(".ol-product-content-detail__images-list-single").slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: !1,
                        fade: !1,
                        asNavFor: ".ol-product-content-detail__images-list-group"
                    });

                    $(".ol-product-content-detail__images-list-group").slick({
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        asNavFor: ".ol-product-content-detail__images-list-single",
                        dots: !1,
                        arrows: !0,
                        centerMode: !0,
                        focusOnSelect: !0,
                        centerPadding: "0",
                        responsive: [{
                            breakpoint: 891,
                            settings: {
                                slidesToShow: 2,
                            },
                        }, {
                            breakpoint: 550,
                            settings: {
                                slidesToShow: 2,
                            },
                        }],
                    });
                }, 1000);
            // }
        },
        async init(){
            this.jqUnslick();
            this.jqSlick();            
        },
    },
    async mounted(){
        await this.init();

        await Promise.all([
            this.getItems(true),
            
        ]);
    },
    watch:{
        $route(to, from) {
            this.init();
        },
    },
    updated(){
        
    },
};
</script>

<style lang="css" scoped>
@import '../../assets/css/bootstrap.css';
@import '../../assets/css/interior.css';

.detail-interior{
    padding:16px;
}

@media(max-width:767px){
    .detail-interior{
        padding:0px;
    }
}

.ol-product-content-detail__images-list-group-figure.slick-current{
    border: 0px white;
}
</style>
